import { Project, PublicUser } from '@owl-nest/api-client/latest'
import * as shadow from '@owl-nest/shadow'

import { providers, getSharingURL } from './sharing'
import { Platform } from './types'

type SharingHelpers = {
  shareTo: (platform: Platform, extra?: any) => void
}

export function useSharing<PROJECT_EXTRA_FIELDS extends string = ''>(
  context: string,
  project: Project<PROJECT_EXTRA_FIELDS>,
  user?: PublicUser,
): SharingHelpers {
  const tracking = shadow.useTracking()
  return { shareTo }

  function shareTo(platform: Platform, extra?: any): void {
    const URL = getSharingURL(context, platform, project, user)
    providers[platform](URL, (socialAction: string, socialNetwork: shadow.SOCIAL_NETWORK) => {
      tracking.social.track({
        socialAction,
        socialNetwork,
        socialTarget: project.absolute_url,
        ...extra,
      })
    })
  }
}

import * as React from 'react'

import { useTimeout } from '@owl-nest/hooks'

import { copyToClipboard } from './copier'

type CopyStatus = 'failed' | 'idle' | 'succeeded'

type UseCopy = (successDuration?: number) => {
  canCopy: boolean | undefined
  copy: (text: string) => void
  copyStatus: CopyStatus | undefined
}

export const useCopy: UseCopy = (alertDuration = 5000) => {
  const [copyStatus, setCopyStatus] = React.useState<CopyStatus>()
  const [canCopy, setCanCopy] = React.useState<boolean | undefined>()

  React.useEffect(() => {
    setCanCopy(document.queryCommandSupported && document.queryCommandSupported('copy'))
  }, [])

  useTimeout(
    () => {
      setCopyStatus('idle')
    },
    copyStatus !== 'idle' ? alertDuration : 0,
  )

  return {
    canCopy,
    copy,
    copyStatus,
  }

  function copy(text: string): void {
    try {
      const didCopy = copyToClipboard(text)
      setCopyStatus(didCopy ? 'succeeded' : 'failed')
    } catch (exception) {
      setCopyStatus('failed')
    }
  }
}

import { Project, PublicUser } from '@owl-nest/api-client/latest'
import * as date from '@owl-nest/date'
import * as model from '@owl-nest/models'
import { SOCIAL_NETWORK } from '@owl-nest/shadow'
import { text } from '@owl-nest/utils'

import { Platform } from './types'
import * as UFE from './UFE'

/* TODO:
 ** - Handle case where social providers are blocked (mainly Facebook SDK since others are merely anchors)
 ** - Make the Facebook SDK import and init dynamic, invoking it only when user tries to share to FB/Messenger
 */

declare global {
  interface Window {
    FB?: any
  }
}

export const providers = {
  clipboard: () => {},
  facebook: (URL: string, track: (socialAction: string, socialNetwork: SOCIAL_NETWORK) => void) => {
    if (window.FB && URL) {
      track('click: Share', SOCIAL_NETWORK.FACEBOOK)

      window.FB.ui(
        {
          method: 'share',
          href: URL,
        },
        (response: unknown) => {
          if (response !== undefined) {
            track('Share', SOCIAL_NETWORK.FACEBOOK)
          }
        },
      )
    }
  },
  linkedin: (URL: string, track: (socialAction: string, socialNetwork: SOCIAL_NETWORK) => void) => {
    const link = `http://www.linkedin.com/shareArticle?url=${URL}`
    openCenteredWindow(link)
    track('click: Share', SOCIAL_NETWORK.LINKEDIN)
  },
  messenger: (URL: string, track: (socialAction: string, socialNetwork: SOCIAL_NETWORK) => void) => {
    track('click: Share', SOCIAL_NETWORK.MESSENGER)
    // TODO: FIXME: Use a more solid check (based on actual device data, not just viewport)
    if (!window.matchMedia('(min-width: 768px)').matches && UFE.FACEBOOK_APP_ID) {
      window.open(`fb-messenger://share?link=${URL}&app_id=${encodeURIComponent(UFE.FACEBOOK_APP_ID)}`)
    } else if (window.FB) {
      window.FB.ui(
        {
          method: 'send',
          link: URL,
        },
        (response: unknown) => {
          if (response !== undefined) {
            track('Share', SOCIAL_NETWORK.MESSENGER)
          }
        },
      )
    }
  },
  twitter: (URL: string, track: (socialAction: string, socialNetwork: SOCIAL_NETWORK) => void) => {
    const hashtags = encodeURIComponent('ulule,crowdfunding')
    const link = `https://twitter.com/share?url=${URL}&hashtags=${hashtags}`
    openCenteredWindow(link)
    track('click: Share', SOCIAL_NETWORK.TWITTER)
  },
  whatsapp: (URL: string, track: (socialAction: string, socialNetwork: SOCIAL_NETWORK) => void) => {
    openCenteredWindow(`https://api.whatsapp.com/send?text=${URL}"`)
    track('click: Share', SOCIAL_NETWORK.WHATSAPP)
  },
} as const

export function getSharingURL<PROJECT_EXTRA_FIELDS extends string = ''>(
  context: string,
  platform: Platform,
  project: Project<PROJECT_EXTRA_FIELDS>,
  user?: PublicUser,
): string {
  const baseURL = project.sharing_urls ? model.i18n.get(project.sharing_urls) : project.absolute_url
  const platformName = platform === 'clipboard' ? '--http.referer--' : text.capitalize(platform)

  const UTMBase = `utm_campaign=presale_${project.id}&utm_source=shared-from-Ulule-${context}-on-${platformName}`
  const UTMUserData = user?.id !== undefined ? `&utm_medium=uluid_${user.id}` : ''
  // Twitter needs the URL to be URI encoded.
  const UTM =
    platform === 'twitter' || platform === 'whatsapp'
      ? encodeURIComponent(`${UTMBase}${UTMUserData}`)
      : `${UTMBase}${UTMUserData}`

  return `${baseURL}?${UTM}`
}

function openCenteredWindow(URL: string): void {
  // Open in the middle of window
  const height = 400
  const width = 550
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2

  const config = {
    centerscreen: 'yes',
    chrome: 'yes',
    directories: 'no',
    height,
    left,
    location: 'no',
    menubar: 'no',
    resizable: 'no',
    scrollbars: 'yes',
    status: 'no',
    toolbar: 'no',
    top,
    width,
  } as const

  window.open(
    URL,
    undefined,
    Object.keys(config)
      .map(key => `${key}=${config[key as keyof typeof config]}`)
      .join(', '),
  )
}

import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type RoundButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string
  /** @default false */
  active?: boolean
  contents: {
    inactive: React.ReactNode
    inactiveHover?: React.ReactNode
    active: React.ReactNode
    activeHover?: React.ReactNode
  }
  noShadow?: boolean
}

function RoundButtonComponent({
  className,
  active = false,
  contents,
  ...buttonProps
}: RoundButtonProps): React.ReactElement<RoundButtonProps> {
  const [hover, setHover] = React.useState(false)
  const [realActive, setRealActive] = React.useState(active)
  const [realHover, setRealHover] = React.useState(hover)

  React.useEffect(() => {
    setRealActive(active)
    setRealHover(hover)
  }, [hover])

  React.useEffect(() => {
    setRealActive(active)
    setRealHover(false)
  }, [active])

  return (
    <S.button.RoundButton
      className={className}
      active={realActive}
      hover={realHover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...buttonProps}
    >
      {getContent()}
    </S.button.RoundButton>
  )

  function getContent(): React.ReactNode {
    return realHover && realActive
      ? contents.activeHover || contents.active
      : !realHover && realActive
      ? contents.active
      : realHover && !realActive
      ? contents.inactiveHover || contents.inactive
      : !realHover && !realActive
      ? contents.inactive
      : undefined
  }
}

export const RoundButton = styled(RoundButtonComponent)<RoundButtonProps>``
RoundButton.displayName = 'RoundButton'

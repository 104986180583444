import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import * as S from '../styles'

export type LazyImageProps = {
  nativeLazyLoad?: boolean
  children: React.ReactElement<JSX.IntrinsicElements['img']>
}

export function LazyImage({
  nativeLazyLoad,
  children,
}: LazyImageProps): React.ReactElement<JSX.IntrinsicElements['img']> {
  const [imageRef, inView] = useInView({
    rootMargin: '100px 0px 0px 0px',
    triggerOnce: true,
    skip: nativeLazyLoad,
  })

  if (nativeLazyLoad) {
    return React.cloneElement(children, { loading: 'lazy' })
  }

  if (inView) {
    return children
  }

  return (
    <Skeleton>
      {React.cloneElement(children, {
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRF6+TRyQvWMQAAAApJREFUeJxjYAAAAAIAAUivpHEAAAAASUVORK5CYII=',
        ref: imageRef,
      })}
    </Skeleton>
  )
}

const Skeleton = styled.div`
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 1;

    ${S.skeleton.skeletonAnimationRules}
  }
`

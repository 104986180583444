import * as React from 'react'
import styled from 'styled-components'

import { t, tc } from '@owl-nest/localize'
import * as services from '@owl-nest/services'
import { searchParams } from '@owl-nest/utils'
import * as plume from '@ulule/owl-kit-components/next'

import * as UFE from '../../UFE'

type FollowProjectProps = {
  followImage?: {
    '1x': string
    '2x': string
    '3x': string
  }
  isOpen: boolean
  onClose: () => void
}

export function FollowProject({ isOpen, onClose }: FollowProjectProps): React.ReactElement<FollowProjectProps> {
  const auth = services.user.useAuth()

  return (
    <ModalWithIcon
      closable
      open={isOpen}
      onClose={onClose}
      icon={<plume.illustrations.twoToned.EnvelopeWithHeart size={120} noBackground />}
      svgScale={0.825}
    >
      <ModalContent>
        <>
          {auth.type === 'loggedin' ? (
            <>
              <plume.styles.heading.XXS>{t('Project followed')}</plume.styles.heading.XXS>
              <plume.styles.copy.S>
                {t('You will receive project posts and be notified by email 48 hours before the end of the project.')}
              </plume.styles.copy.S>
              <plume.Button onClick={onClose}>{t('Ok, thanks')}</plume.Button>
            </>
          ) : (
            <>
              <plume.styles.heading.XXS>{t('Follow this project')}</plume.styles.heading.XXS>
              <plume.styles.copy.S>
                {tc(
                  `[loginLink: Log in] to your Ulule account to follow this project. Don't have an account? [signupLink: Sign up] now.`,
                  {
                    loginLink: <plume.Link href={nextAwareURL(UFE.SIGNIN_URL)} tinted />,
                    signupLink: <plume.Link href={nextAwareURL(UFE.SIGNUP_URL)} tinted />,
                  },
                )}
              </plume.styles.copy.S>
              <br />
              <plume.styles.copy.XS>
                <plume.Link kind="secondary" onClick={() => onClose()}>
                  {t('Later')}
                </plume.Link>
              </plume.styles.copy.XS>
            </>
          )}
        </>
      </ModalContent>
    </ModalWithIcon>
  )
}

function nextAwareURL(url: string): string {
  if (typeof window === 'undefined') {
    return url
  }

  const params = { next: window.location.href }
  return searchParams.addSearchParamsToUrl(url, params)
}

const ModalWithIcon = styled(plume.ModalWithIcon)``

const ModalContent = styled.div`
  text-align: center;

  ${plume.Button} {
    margin-top: 30px;
  }

  ${plume.styles.heading.XXS} {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

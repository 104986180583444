import * as logger from '@owl-nest/logger'

// TODO: FIXME: Test
/**
 * Copies a given text string to the user's clipboard.
 *
 * @param text — the string to copy
 * @returns A boolean representing whether or not the copy was successful

 * @throws Will throw an exception if the `copy` command fails
 */
export function copyToClipboard(text: string): boolean {
  const textArea = document.createElement('textarea')

  textArea.style.position = 'fixed'
  textArea.style.left = '0'
  textArea.style.top = '0'

  textArea.style.height = '2em'
  textArea.style.width = '2em'

  textArea.style.background = 'transparent'
  textArea.style.border = 'none'
  textArea.style.boxShadow = 'none'
  textArea.style.outline = 'none'
  textArea.style.padding = '0'

  textArea.value = text

  document.body.appendChild(textArea)
  textArea.focus()

  // This is required for iOS version <13
  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange()
    range.selectNodeContents(textArea)
    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
    textArea.setSelectionRange(0, 999999)
  } else textArea.select()

  try {
    return document.execCommand('copy')
  } catch (exception) {
    logger.err('[copier] Failed copying to clipboard', {
      exception,
      textToCopy: text,
    })
    throw exception
  } finally {
    document.body.removeChild(textArea)
  }
}

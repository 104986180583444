import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type TooltipProps = {
  children: React.ReactNode
  /** @default false */
  fitToContent?: boolean
  open: boolean
  position?: 'bottom' | 'top' | 'right' | 'left'
}

function TooltipComponent({
  children,
  fitToContent = false,
  open = false,
  position,
  ...props
}: TooltipProps): React.ReactElement<TooltipProps> {
  return (
    <S.tooltip.Tooltip {...props} fitToContent={fitToContent} open={open} position={position}>
      {children}
    </S.tooltip.Tooltip>
  )
}

export const Tooltip = styled(TooltipComponent)<TooltipProps>``
Tooltip.displayName = 'Tooltip'

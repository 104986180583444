import { lens } from '@owl-nest/config'
import { AuthenticatedUser } from '@owl-nest/api-client/src/version/2023-09-12'

type Environment = {
  PROJECT_DATA: ProjectData
  UFE: {
    urls: {
      signin: string
      signup: string
    }
    user: AuthenticatedUser & {
      is_authenticated: boolean
      nb_projects: number
      project_list_url?: string
      settings_url?: string
      transactions_url: string
    }
  }
}

type ProjectData = {
  images: {
    following: {
      '1x': string
      '2x': string
      '3x': string
    }
  }
}

const windowLens = lens<Environment>()

const global =
  typeof process !== 'undefined'
    ? {
        PROJECT_DATA: process.env.PROJECT_DATA ? JSON.parse(process.env.PROJECT_DATA) : undefined,
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
      }
    : window

export const PROJECT_REMINDER_IMAGE = windowLens.get('PROJECT_DATA').get('images').get('following').value(global, {
  forgive: true,
  silent: true,
  trust: true,
})

export const CURRENT_USER = windowLens.get('UFE').get('user').value(global, { trust: true })

export const SIGNIN_URL = windowLens.get('UFE').get('urls').get('signin').value(global)

export const SIGNUP_URL = windowLens.get('UFE').get('urls').get('signup').value(global)

import * as React from 'react'
import styled, { css } from 'styled-components'

import * as S from '../styles'
import type * as picture from '../styles/picture'
import { LazyImage } from './LazyImage'

export type PictureProps = picture.PictureProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    nativeLazyLoad?: boolean
    sizeof?: string
  }

function PictureComponent({
  className,
  sizing = 'cover',
  sizeof,
  src,
  srcSet,
  loading = 'lazy',
  nativeLazyLoad = false,
  ...imageProps
}: PictureProps): React.ReactElement<PictureProps> {
  return (
    <S.picture.Picture className={className}>
      {loading === 'lazy' ? (
        <LazyImage nativeLazyLoad={nativeLazyLoad}>
          <S.picture.Image src={src} srcSet={srcSet} sizing={sizing} loading="lazy" {...imageProps} />
        </LazyImage>
      ) : (
        <S.picture.Image src={src} srcSet={srcSet} sizing={sizing} {...imageProps} />
      )}
      {sizeof && <Sizer src={sizeof} srcSet={srcSet} sizing={sizing} {...imageProps} />}
    </S.picture.Picture>
  )
}

export const Sizer = styled(S.picture.Image)`
  opacity: 0;
`

export const Picture = styled(PictureComponent)`
  ${({ sizeof }) => {
    if (sizeof !== undefined) {
      return css`
        position: relative;
        ${S.picture.Image}:not(${Sizer}) {
          position: absolute;
          top: 0;
          height: 100%;
        }
      `
    }
  }}
`

import * as React from 'react'
import * as jotai from 'jotai'

// To each modale is associated an `open` boolean and some params. Params are
// set when opening the modal (to forward some values from the call site to the
// modal). Those params can be accessed for a modal via a separate
// `useModalParams` hook (used inside the modal)
const MODAL_ATOM = jotai.atom<Record<string, { open: boolean; params?: any }>>({})

// List of ids of all modals using the `useModal` hook. The `key` is the unique
// id of your modale (that you will pass when using the hooks in this files).
// The "value" type is the type of the parameters of your modal. For simple case
// it is usually void. For some case (like a confirmation for a deletion) you
// need to hold some data to be accessed inside the modale (like the id of the
// entity to delete to be able to actually delete it from inside the modale when
// the user confirms the action).
type Modals = {
  AbortShippingZoneEditionPanel: void
  AnalyticsCookieWarning: void
  DeleteCustomDeliveryConfirmation: void
  DeleteRewardConfirmation: { rewardId: number }
  DeleteShippingZone: { delete: () => void }
  DonationTaxDeductionInfo: void
  CancelSubscription: void
  SuccessFreeSubscription: void
  CommunityImportChange: void
  CommunityImportEmailPreview: void
  FundraisingMethod: void
  DiscordLogout: void
  MondialRelayAddressPanel: void
  Nsfw: void
  NewsEditionPanel: {
    id: number | undefined
  }
  NewsEditionPanelCancelPostCreation: void
  NewsEditionPanelCancelPostModification: void
  NewsEditionPanelDeletePost: void
  NewsEditionPanelDeletePostTranslation: void
  NewsEditionPanelSavePostBeforePreviewModal: void
  NewsEditionPanelUnpublishPost: void
  OrderPanel: void
  ReferralsDetailPanel: {
    id: number | undefined
  }
  ProjectDetailLaunchShare: void
  ProjectDetailShare: void
  RewardTaxDeductionInfo: void
  ShippingZoneEditionPanel: { zoneId?: number }
}

type ModalValue<ID extends keyof Modals> = { open: boolean; params?: Modals[ID] }

type ModalAtom<ID extends keyof Modals> = jotai.WritableAtom<
  ModalValue<ID>,
  [ModalValue<ID> | ((value: ModalValue<ID>) => ModalValue<ID>)],
  void
>

function useModalAtom<ID extends keyof Modals>(id: ID): ModalAtom<ID> {
  return React.useMemo(() => {
    return jotai.atom<ModalValue<ID>, [ModalValue<ID> | ((value: ModalValue<ID>) => ModalValue<ID>)], void>(
      // the value of the atom is the value in the `MODAL_ATOM` at the `id` key.
      (get) => {
        return get(MODAL_ATOM)[id] ?? { open: false }
      },
      // updating the atom means updating the value of `MODAL_ATOM` at the `id`
      // key (bu only if the value did change)
      (get, set, update) => {
        const currentState = get(MODAL_ATOM)
        const currentValue = currentState[id]
        const nextValue = typeof update === 'function' ? update(currentValue) : update
        if (nextValue !== currentValue) {
          set(MODAL_ATOM, { ...currentState, [id]: nextValue })
        }
      },
    )
  }, [id])
}

type SetModal<ID extends keyof Modals> = (open: boolean, params?: Modals[ID]) => void

function _useSetModal<ID extends keyof Modals>(modalAtom: ModalAtom<ID>): SetModal<ID> {
  const setModalValue = jotai.useSetAtom(modalAtom)

  const setValue = React.useCallback(
    (open: boolean, params?: Modals[ID]) => {
      setModalValue({ open, params })
    },
    [modalAtom],
  )

  return setValue
}

function _useModalOpen<ID extends keyof Modals>(modalAtom: ModalAtom<ID>): boolean {
  const atomValue = jotai.useAtomValue(modalAtom)

  return atomValue.open
}

export function useModalParams<ID extends keyof Modals>(id: ID): Modals[ID] | undefined {
  const modalAtom = useModalAtom(id)
  const atomValue = jotai.useAtomValue(modalAtom)

  return atomValue.params
}

export function useSetModal<ID extends keyof Modals>(id: ID): SetModal<ID> {
  const modalAtom = useModalAtom(id)
  return _useSetModal(modalAtom)
}

export function useModalOpen<ID extends keyof Modals>(id: ID): boolean {
  const modalAtom = useModalAtom(id)
  return _useModalOpen(modalAtom)
}

export function useModal<ID extends keyof Modals>(id: ID): [boolean, SetModal<ID>] {
  const modalAtom = useModalAtom(id)
  return [_useModalOpen(modalAtom), _useSetModal(modalAtom)]
}

export function useModalInitialValue<ID extends keyof Modals>(
  modalId: ID,
  initialValue: { open: boolean; params?: Modals[ID] },
): void {
  const store = jotai.useStore()

  if (!store.get(MODAL_ATOM)[modalId]) {
    store.set(MODAL_ATOM, (currentValue) => {
      return { ...currentValue, [modalId]: initialValue }
    })
  }
}

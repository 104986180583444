import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

export function Waves(): React.ReactElement {
  return (
    <WavesContainer>
      <WavesSvg viewBox="0 0 1225 1142" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M532.816 477.314C655.383 548.116 772.572 455.191 900.329 510.02C966.801 538.547 986.132 565.879 1040.31 610.194C1075.05 638.618 1079 643.649 1110.1 663.735C1141.21 683.82 1193.86 706.393 1193.86 706.393L1214.84 783.662L12.9963 830.681L-126.767 315.932L160.025 304.712C160.025 304.712 312.543 296.289 403.217 357.21C458.366 394.263 470.073 441.07 532.816 477.314Z"
          fill="#D1EDF7"
        />
        <path
          d="M509.698 518.992C632.253 589.39 744.194 477.787 871.939 532.302C938.404 560.666 998.453 668.015 1024.13 699.431C1038.2 716.645 1056.68 731.912 1083.04 743.32C1109.4 754.727 1170.69 746.76 1170.69 746.76L1191.66 823.589L-10.0997 870.347L-141.056 390.672L127.862 380.209C127.862 380.209 314.76 366.721 388.161 408.396C452.22 444.767 446.961 482.955 509.698 518.992Z"
          fill="#E7F8FC"
        />
      </WavesSvg>
    </WavesContainer>
  )
}

const WavesContainer = styled.div`
  bottom: 0;
  height: 500px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
`

const WavesSvg = styled.svg`
  bottom: -375px;
  position: absolute;
  width: 1050px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    bottom: -325px;
    width: 850px;
    left: -150px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP} {
    bottom: -400px;
    width: 980px;
    left: -100px;
  }

  @media screen and ${plume.BREAKPOINTS.LAPTOP_L} {
    bottom: -440px;
    width: 65%;
    max-width: 1100px;
    left: 0px;
  }

  @media screen and (min-width: 1920px) {
    bottom: -540px;
    width: 65%;
    max-width: 1300px;
  }
`

import { COLORS, ZINDEX } from '@ulule/owl-kit-components/next'

const params = {
  colors: [COLORS.PRIMARY_BLUE_500, COLORS.SECONDARY_GREEN_500, COLORS.BRAND_INDIGO, COLORS.SECONDARY_PURPLE],
  disableForReducedMotion: true,
  startVelocity: 30,
  spread: 360,
  ticks: 60,
  zIndex: ZINDEX.HIGH,
}

let confettiPromise: Promise<any> | undefined = undefined

export async function renderConfetti(canvas?: any): Promise<void> {
  if (confettiPromise === undefined) {
    //@ts-expect-error: no type definition for this module
    confettiPromise = import('canvas-confetti')
  }

  const end = Date.now() + 3 * 1000
  const { default: confetti } = await confettiPromise

  if (canvas !== undefined && canvas.confetti === undefined) {
    canvas.confetti = confetti.create(canvas, { resize: true })
  }

  const interval: number = window.setInterval(async () => {
    if (Date.now() > end) {
      return clearInterval(interval)
    }

    const confettiParams = {
      ...params,
      origin: {
        x: Math.random(),
        y: Math.random() - 0.2,
      },
    }

    if (canvas !== undefined) {
      canvas.confetti(confettiParams)
    } else {
      confetti(confettiParams)
    }
  }, 150 + Math.random() * 150)
}

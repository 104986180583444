import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'
import * as colors from '../../../constants/colors'

const ID = 'messenger-third-party-glyph-icon'

export function MessengerSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <g>
        <g clipPath="url(#messenger-a)">
          <path
            fillRule="evenodd"
            d="M0 15.52C0 6.604 6.988 0 16 0s16 6.608 16 15.524-6.988 15.52-16 15.52c-1.62 0-3.172-.216-4.632-.616a1.286 1.286 0 0 0-.856.064l-3.176 1.4A1.28 1.28 0 0 1 5.54 30.76l-.088-2.848A1.264 1.264 0 0 0 5.024 27C1.912 24.216 0 20.184 0 15.52Zm11.091-2.916-4.7 7.456c-.451.716.429 1.52 1.1 1.008l5.049-3.832a.961.961 0 0 1 1.155-.004l3.74 2.804a2.4 2.4 0 0 0 3.473-.64l4.703-7.452c.448-.716-.431-1.524-1.104-1.012l-5.048 3.832a.96.96 0 0 1-1.155.004l-3.74-2.804a2.4 2.4 0 0 0-3.473.64Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="messenger-a">
            <path fill={colors.PRIMARY_GREY_000} d="M0 0h32v32H0z" />
          </clipPath>
        </defs>
      </g>
    </symbol>
  )
}

const MessengerStandaloneContent = standalone(MessengerSymbol, ID)

export const Messenger = styled(function MessengerStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-messenger">
      <MessengerStandaloneContent />
    </Icon>
  )
})``

export const MessengerLightweight = styled(function MessengerComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-messenger">
      {lightweight(ID)}
    </Icon>
  )
})``

import { lens } from '@owl-nest/config'

type FacebookInit = {
  appId: string
  locale: string
}

type UFE = {
  facebook: FacebookInit
}

type Window = {
  UFE: UFE
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
      }
    : window

export const FACEBOOK_APP_ID = windowLens.get('UFE').get('facebook').get('appId').value(global, { forgive: true })

import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'

import { TagColor } from '../../styles/tag'

export type TagProps = {
  size?: 'regular' | 'small'
  color?: TagColor
  icon?: React.ReactElement
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>

function TagComponent({ children, color, size = 'regular', icon, ...props }: TagProps): React.ReactElement<TagProps> {
  return (
    <S.tag.Tag color={color} size={size} {...props}>
      {icon}
      {children}
    </S.tag.Tag>
  )
}

export const Tag = styled(TagComponent)<TagProps>``
Tag.displayName = 'Tag'

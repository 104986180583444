import * as React from 'react'

import styled, { css } from 'styled-components'

import * as api from '@owl-nest/api-client/next'
import { renderConfetti } from '@owl-nest/confetti'
import * as copier from '@owl-nest/copier'
import * as hooks from '@owl-nest/hooks'
import { t } from '@owl-nest/localize'
import * as shadow from '@owl-nest/shadow'
import * as sharing from '@owl-nest/sharing'
import * as plume from '@ulule/owl-kit-components/next'

import * as UFE from '../../UFE'

type ShareModalProps = {
  closable?: boolean
  enableConfetti?: boolean
  enableWidget?: boolean
  modalButtons?: plume.ModalButtons
  modalPastille?: React.ReactElement
  modalHero?: React.ReactElement
  modalId?: 'ProjectDetailShare' | 'ProjectDetailLaunchShare'
  project: api.Project
  publicUser?: api.PublicUser
  sharingContext?: string
  trackingConfig: {
    category: shadow.CATEGORY
    source: string
  }
  withCallout?: boolean
}

const ProjectWidgetPreview = React.lazy(async () => ({ default: (await import('@ulule/duvet')).ProjectWidgetPreview }))

export function ShareProject({
  closable = true,
  enableConfetti = false,
  enableWidget = true,
  modalButtons,
  modalHero = <plume.styles.heading.L>{t('Share project')}</plume.styles.heading.L>,
  modalId = 'ProjectDetailShare',
  modalPastille = <plume.illustrations.twoToned.Share size={plume.utils.isMobile() ? 52 : 120} />,
  project,
  publicUser = UFE.CURRENT_USER,
  sharingContext = 'project-page',
  trackingConfig,
  withCallout = false,
}: ShareModalProps): React.ReactElement {
  const { canCopy, copy, copyStatus } = copier.useCopy(4000)
  const { shareTo } = sharing.useSharing(sharingContext, project, publicUser)
  const tracking = shadow.useTracking()

  const [isProjectDetailShareModalOpen, setIsProjectDetailShareModalOpen] = hooks.useModal(modalId)
  const [isWidgetOpen, setIsWidgetOpen] = React.useState(false)

  plume.hooks.useScrollLock(isProjectDetailShareModalOpen, { lockClassName: 'no-scroll' })

  React.useEffect(() => {
    if (enableConfetti && isProjectDetailShareModalOpen) {
      const canvas = document.getElementById('confettis-canvas')
      renderConfetti(canvas)
    }
  }, [isProjectDetailShareModalOpen])

  const projectURL = sharing.getSharingURL(sharingContext, 'clipboard', project, publicUser)

  return (
    <ModalWithIcon
      buttons={modalButtons}
      closable={closable}
      onClose={() => {
        setIsProjectDetailShareModalOpen(false)
      }}
      open={isProjectDetailShareModalOpen}
      withCallout={withCallout}
      icon={modalPastille}
      svgScale={0.8}
    >
      <OverflowWrapper isWidgetOpen={isWidgetOpen}>
        {enableConfetti && <CanvasConfetti id="confettis-canvas" />}

        {modalHero}

        <Copy>
          <plume.TextField value={projectURL} disabled />
          <plume.LinkAsButton
            type="button"
            tinted
            withIcon
            onClick={() => {
              if (canCopy) {
                copy(projectURL)
                track(shadow.SOCIAL_NETWORK.HYPERLINK)
              }
            }}
          >
            <plume.glyphs.stroke.Link size={20} />
            {t('Copy link')}
          </plume.LinkAsButton>

          <plume.Tooltip open={copyStatus === 'succeeded'} position={'bottom'} fitToContent>
            <plume.styles.copy.S>{t('Link copied to your clipboard.')}</plume.styles.copy.S>
          </plume.Tooltip>
        </Copy>

        <Links>
          <plume.Link
            withIcon
            className="lauch-share-modal-linkedin"
            onClick={() => {
              shareTo('linkedin', { eventCategory: trackingConfig.category, eventLabel: trackingConfig.source })
            }}
          >
            <plume.glyphs.thirdParty.Linkedin size={16} />
            Linkedin
          </plume.Link>

          <plume.Link
            withIcon
            className="lauch-share-modal-whatsapp"
            onClick={() =>
              shareTo('whatsapp', { eventCategory: trackingConfig.category, eventLabel: trackingConfig.source })
            }
          >
            <plume.glyphs.thirdParty.WhatsApp size={16} />
            WhatsApp
          </plume.Link>

          <plume.Link
            withIcon
            className="lauch-share-modal-facebook"
            onClick={() =>
              shareTo('facebook', { eventCategory: trackingConfig.category, eventLabel: trackingConfig.source })
            }
          >
            <plume.glyphs.thirdParty.Facebook size={16} />
            Facebook
          </plume.Link>

          <plume.Link
            withIcon
            className="lauch-share-modal-messenger"
            onClick={() =>
              shareTo('messenger', { eventCategory: trackingConfig.category, eventLabel: trackingConfig.source })
            }
          >
            <plume.glyphs.thirdParty.Messenger size={16} />
            Messenger
          </plume.Link>

          <plume.Link
            withIcon
            className="lauch-share-modal-twitter"
            onClick={() =>
              shareTo('twitter', { eventCategory: trackingConfig.category, eventLabel: trackingConfig.source })
            }
          >
            <plume.glyphs.thirdParty.Twitter size={16} />
            Twitter
          </plume.Link>

          {enableWidget && (
            <plume.Link
              withIcon
              className="lauch-share-modal-widget"
              onClick={() => {
                setIsWidgetOpen(!isWidgetOpen)
                track(shadow.SOCIAL_NETWORK.WIDGET)
              }}
            >
              <plume.glyphs.stroke.CodeBlock size={18} />
              {t('Embed widget')}
            </plume.Link>
          )}
        </Links>

        {enableWidget && (
          <React.Suspense fallback={<plume.Spinner />}>
            <Widget open={isWidgetOpen}>
              <ProjectWidgetPreview url={`${project.absolute_url}widget.html`} />
            </Widget>
          </React.Suspense>
        )}
      </OverflowWrapper>
    </ModalWithIcon>
  )

  function track(kind: shadow.SOCIAL_NETWORK): void {
    tracking.social.track({
      eventCategory: trackingConfig.category,
      eventLabel: trackingConfig.source,
      socialAction: 'click: Share',
      socialNetwork: kind,
      socialTarget: project.absolute_url,
    })
  }
}

const OverflowWrapper = styled.div<{ isWidgetOpen: boolean }>`
  overflow: ${({ isWidgetOpen }) => (isWidgetOpen ? 'auto' : 'initial')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 75vh;
`

const CanvasConfetti = styled.canvas`
  height: 170px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`

const ModalWithIcon = styled(plume.ModalWithIcon)<{ withCallout: boolean }>`
  ${plume.styles.modal.Body}:not(${plume.styles.modal.Actions}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
  }

  ${plume.styles.modal.Actions} button {
    width: fit-content;
  }

  ${plume.illustrations.Illustration} {
    position: relative;
    z-index: 1;
  }

  ${plume.styles.heading.L} {
    margin-top: 13px;
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
  }

  ${plume.styles.copy.M} {
    margin-bottom: ${({ withCallout }) => (withCallout ? '20px' : '32px')};
  }

  ${plume.Link} {
    ${plume.glyphs.Icon} {
      margin-right: 5px;
    }
  }

  ${plume.glyphs.thirdParty.Linkedin} {
    color: ${plume.COLORS.TERTIARY_LINKEDIN};
  }

  ${plume.glyphs.thirdParty.WhatsApp} {
    color: ${plume.COLORS.TERTIARY_WHATSAPP};
  }

  ${plume.glyphs.thirdParty.Facebook} {
    color: ${plume.COLORS.TERTIARY_FACEBOOK};
  }

  ${plume.glyphs.thirdParty.Messenger} {
    color: ${plume.COLORS.TERTIARY_MESSENGER};
  }

  ${plume.glyphs.thirdParty.Twitter} {
    color: ${plume.COLORS.TERTIARY_X};
  }

  ${plume.glyphs.stroke.CodeBlock} {
    color: ${plume.COLORS.PRIMARY_SAND_500};
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${plume.styles.copy.M} {
      margin-bottom: ${({ withCallout }) => (withCallout ? '20px' : '32px')};
      width: 546px;
    }

    ${plume.styles.modal.Body}:not(${plume.styles.modal.Actions}) {
      padding-bottom: 40px;
    }

    ${plume.styles.modal.Wrapper} {
      max-width: 734px;
      width: 100%;
      position: relative;

      // HACK: remove this when we correct the zindex issue on project page with all modales
      ${({ open }) => {
        if (open) {
          return css`
            max-height: 100%; //HACK: but don't remove this when we correct the zindex issue
          `
        }
      }}
    }

    ${plume.styles.copy.M} {
      width: 546px;
    }
  }
`

const Copy = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 34px;
  position: relative;
  flex-direction: column;
  width: 100%;

  ${plume.glyphs.stroke.Link} {
    color: ${({ theme }) => theme?.colors?.primary ?? plume.COLORS.PRIMARY_BLUE_500};
  }

  ${plume.TextField} {
    width: 100%;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    min-width: 470px;
    justify-content: center;
    flex-direction: row;
    width: auto;

    ${plume.TextField} {
      min-width: 350px;
      width: auto;
    }

    ${plume.Tooltip} {
      right: -180px;
      left: auto;

      ${plume.styles.copy.S} {
        text-align: center;
      }
    }
  }
`

const Links = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  justify-content: space-evenly;
  max-width: 272px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    max-width: 100%;
  }
`

const Widget = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: 34px;
`

import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'

const ID = 'whatsapp-third-party-glyph-icon'

export function WhatsAppSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <g>
        <path
          fillRule="evenodd"
          d="M2.6 23.392.42 30.986l8.142-2.038a15.927 15.927 0 0 0 7.338 1.784h.007c8.464 0 15.354-6.575 15.357-14.656.002-3.917-1.594-7.599-4.493-10.37-2.9-2.77-6.756-4.296-10.864-4.298C7.442 1.408.553 7.983.55 16.064c-.003 2.572.704 5.1 2.05 7.328Zm.542-7.327C3.145 9.348 8.87 3.884 15.912 3.884c1.676-.004 3.337.31 4.886.923a12.733 12.733 0 0 1 4.137 2.65 12.087 12.087 0 0 1 2.772 3.951c.641 1.48.969 3.066.963 4.667-.002 6.717-5.728 12.182-12.764 12.182h-.004a13.21 13.21 0 0 1-6.497-1.698l-.466-.264-4.832 1.21 1.29-4.496-.304-.46a11.749 11.749 0 0 1-1.95-6.484Z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M12.068 9.937c-.287-.61-.59-.622-.863-.633l-.736-.009a1.44 1.44 0 0 0-1.023.459c-.351.366-1.343 1.253-1.343 3.055 0 1.802 1.375 3.544 1.567 3.789.191.244 2.654 4.06 6.555 5.53 3.24 1.22 3.9.977 4.604.916.704-.061 2.27-.886 2.59-1.742.32-.855.32-1.588.224-1.741-.096-.154-.352-.245-.736-.428s-2.27-1.07-2.622-1.192c-.351-.122-.607-.183-.863.184-.256.366-.99 1.191-1.215 1.436-.224.244-.447.275-.831.092-.384-.183-1.62-.57-3.086-1.819-1.14-.97-1.91-2.17-2.135-2.536-.224-.367-.024-.565.169-.748.172-.164.383-.427.576-.641.192-.214.255-.367.383-.611s.064-.459-.032-.642-.842-1.994-1.183-2.719"
          clipRule="evenodd"
        />
      </g>
    </symbol>
  )
}

const WhatsAppStandaloneContent = standalone(WhatsAppSymbol, ID)

export const WhatsApp = styled(function WhatsAppStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-whatsapp">
      <WhatsAppStandaloneContent />
    </Icon>
  )
})``

export const WhatsappLightweight = styled(function WhatsAppComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-whatsapp">
      {lightweight(ID)}
    </Icon>
  )
})``

import * as React from 'react'
import styled from 'styled-components'

import * as cookie from '@owl-nest/cookie-helper'
import * as dateUtil from '@owl-nest/date'
import * as hooks from '@owl-nest/hooks'
import { t, tc } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

type NsfwProps = {
  onBack: () => void
  projectId: number
  projectName: string
  projectSlug: string
}

export function Nsfw({ onBack, projectId, projectName, projectSlug }: NsfwProps): React.ReactElement {
  hooks.useModalInitialValue('Nsfw', { open: true })
  const [isOpen, setIsOpen] = hooks.useModal('Nsfw')

  plume.hooks.useScrollLock(isOpen)

  const isClientSide = hooks.useClientSide() // HACK: This is a dumb workaround to avoid SSR issues with buttons, remove when fixed
  const isMobile = isClientSide ? plume.utils.isMobile() : false

  return (
    <ModalWithIcon
      open={isOpen}
      closable={false}
      onClose={() => {
        setIsOpen(false)
        cookie.setCookie(`accept_pegi_18_${projectId}`, 'true', {
          expires: dateUtil.addDays(Date.now(), 365).toUTCString(),
          path: `/${projectSlug}`,
        })
      }}
      icon={<plume.illustrations.twoToned.Nsfw size={isMobile ? 72 : 120} inverted noBackground />}
      forceInitMount
      showMobileIcon
      buttons={
        isClientSide
          ? [
              {
                type: 'button',
                props: {
                  children: t('Continue'),
                  onClick: () => {
                    setIsOpen(false)
                    cookie.setCookie(`accept_pegi_18_${projectId}`, 'true', {
                      expires: dateUtil.addDays(Date.now(), 365).toUTCString(),
                      path: `/${projectSlug}`,
                    })
                  },
                },
              },
              { type: 'link', props: { children: t('Back to home page'), kind: 'secondary', onClick: onBack } },
            ]
          : undefined
      }
    >
      <Heading>{t('Explicit content')}</Heading>
      <plume.styles.copy.M>
        {tc(
          'Please be aware that the content of [strong: %(projectName)s] is intended for an adult audience. By continuing, you testify being 18 years old or more.',
          { strong: <strong />, projectName },
        )}
      </plume.styles.copy.M>
    </ModalWithIcon>
  )
}

const ModalWithIcon = styled(plume.ModalWithIcon)`
  backdrop-filter: blur(12px);

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${plume.ModalIconWrapper} {
      background-color: ${plume.COLORS.SECONDARY_GREEN_800};
    }
  }
`

const Heading = styled(plume.styles.heading.S)`
  margin-bottom: 8px;
  text-align: center;
`

import * as React from 'react'

export function useTimeout(callback: () => void, delay = 0): () => void {
  const timeoutIDRef = React.useRef<number>()

  React.useEffect(() => {
    timeoutIDRef.current = window.setTimeout(callback, delay)
    return cancelTimer
  }, [callback, delay])

  return cancelTimer

  function cancelTimer(): void {
    const timeoutID = timeoutIDRef.current
    if (timeoutID) {
      timeoutIDRef.current = undefined
      window.clearTimeout(timeoutID)
    }
  }
}
